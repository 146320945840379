import styled from "styled-components";

export const IntroWrapper = styled.div`
  .intro {
    display: flex;
    max-width: 1136px;
    width: 100%;
    gap: 64px;

    .mobile-title {
      @media (max-width: 1101px) {
        display: flex;
      }
      width: fit-content;
      padding-top: 64px;
      &.experience {
        padding-top: 96px;
      }
      h1 {
        color: rgb(226 232 240);
        font-size: 16px;
        letter-spacing: 4px;
        margin: 0;
      }
    }

    .personal-info {
      display: flex;
      flex-direction: column;
      text-align: left;
      @media (min-width: 1101px) {
        max-height: 100vh;
        width: 50%;
        position: sticky;
        top: 128px;
        height: 100vh;
      }
    }
    h2 {
      margin: 0;
      font-size: 40px;
    }
    h2,
    p {
      color: rgb(226, 232, 240);
    }
    p {
      font-size: 20px;
      margin: 0;
      padding-top: 8px;
    }
    span {
      padding-top: 16px;
      color: rgb(148, 163, 184);
    }
    .social-media {
      display: flex;
      padding-top: 32px;
      svg {
        width: 24px;
        fill: rgb(148, 163, 184);
      }
    }
    .timeline {
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        padding-top: 48px;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      ul,
      li {
        color: rgb(148, 163, 184);
        font-size: 14px;
      }
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        &.expand {
          .line {
            background: rgb(226, 232, 240);
            width: 48px;
          }
          li {
            color: rgb(226, 232, 240);
          }
        }
      }
      .line {
        background: rgb(148, 163, 184);
        width: 24px;
        height: 1px;
        transition: width 0.3s ease-in-out;
      }
      li {
        padding-left: 16px;
      }
    }
  }
  .text-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .bio {
    display: flex;
    flex-direction: column;
    width: 50%;
    right: 0;
    bottom: 0;
    background: #0f172a;
    h1,
    h2 {
      color: rgb(226, 232, 240);
    }
    h2 {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: rgb(148, 163, 184);
      .highlight {
        color: rgb(226, 232, 240);
      }
    }
    .focus-at-the-moment,
    .not-in-comp {
      p {
        color: rgb(148, 163, 184);
        font-size: 16px;
        text-align: left;
      }
      .highlight {
        color: rgb(226, 232, 240);
      }
    }
    @media (max-width: 1100px) {
      .focus-at-the-moment,
      .not-in-comp {
        p {
          font-size: 18px;
        }
      }
    }
  }

  .experience {
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding-top: 48px;
  }

  .projects {
    display: flex;
    h2 {
      color: rgb(226, 232, 240);
      padding-top: 400px;
    }
  }

  @media (min-width: 1101px) {
    .mobile-title {
      display: none;
    }
  }

  @media (max-width: 1100px) {
    .intro {
      flex-direction: column;
      gap: 32px;
      p {
        padding-top: 0px;
      }
      .bio {
        width: 100%;
      }
    }
    .personal-info {
      width: 100%;
      position: relative;
      top: 0px;
      span {
        font-size: 18px;
      }
    }
    .text-content {
      h2 {
        font-size: 18px;
      }
      gap: 0px;
    }
    .experience {
      padding-top: 32px;
    }
    .home.timeline {
      display: none;
    }
  }
`;
