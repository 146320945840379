import React from "react";

export default function TextBio({ aboutRef }) {
  return (
    <div className="text-content" ref={aboutRef}>
      <h2>
        Hi, I'm Federico Huneeus,{" "}
        <span className="highlight">Industrial Civil Engineer</span> from{" "}
        <span className="highlight">
          Pontificia Universidad Católica de Chile.
        </span>
      </h2>
      <div className="focus-at-the-moment">
        <p>
          My main focus at the moment is{" "}
          <span className="highlight">building pixel perfect apps</span> that
          create an excellent experience.
        </p>
      </div>
      <div className="not-in-comp">
        <p>
          When I'm not in the computer I enjoy doing sports such as{" "}
          <span className="highlight">surfing and boxing</span>, listening to
          good music and{" "}
          <span className="highlight">
            spending time with family and friends.
          </span>
        </p>
      </div>
    </div>
  );
}
