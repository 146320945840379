import { IntroWrapper } from "./Intro.styles";
import ExperienceCard from "../ExperienceCard/ExperienceCard";
import { jobsInfo } from "../../assets/jobsInfo";
import LinkedIn from "../../assets/Icons/LinkedIn";
import { useInViewStates } from "../../hooks/useInViewStates";
import Timeline from "../Timeline/Timeline";
import TextBio from "../TextBio/TextBio";

export default function Intro() {
  const { aboutVisible, experienceVisible, aboutRef, experienceRef } =
    useInViewStates();

  return (
    <IntroWrapper>
      <div className="intro">
        <div className="personal-info">
          <h2>Federico Huneeus</h2>
          <p>Software Engineer</p>
          <span>
            I build pixel-perfect, engaging, and accessible digital experiences.
          </span>
          <div className="social-media">
            <a
              href="https://www.linkedin.com/in/federico-huneeus-garc%C3%ADa-a27b7354/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedIn />
            </a>
          </div>
          <Timeline
            aboutVisible={aboutVisible}
            experienceVisible={experienceVisible}
          />
        </div>
        <div className="mobile-title">
          <h1>ABOUT</h1>
        </div>
        <div className="bio">
          <TextBio aboutRef={aboutRef} />
          <div className="mobile-title experience">
            <h1>EXPERIENCE</h1>
          </div>
          <div className="experience" ref={experienceRef}>
            {jobsInfo.map((jobInfo, index) => {
              return (
                <ExperienceCard
                  key={index}
                  index={index}
                  time={jobInfo.time}
                  jobTitle={jobInfo.title}
                  jobSubTitle={jobInfo.jobSubTitle}
                  bulletPoints={jobInfo.bulletPoints}
                  skills={jobInfo.skills}
                  customRef={experienceRef}
                  url={jobInfo.url}
                />
              );
            })}
          </div>
        </div>
      </div>
    </IntroWrapper>
  );
}
