import styled from "styled-components";

export const ExperienceCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: baseline;
  padding: 32px 16px;
  border-radius: 8px;

  &:hover {
    background-color: rgba(23, 34, 62, 0.4);
    cursor: initial;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    span {
      padding-top: 0px;
      width: 120px;
      text-align: left;
    }
  }
  .job-description {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 4px;
    .title-container {
      display: flex;
      flex-direction: row;
      svg {
        width: 20px;
        color: rgb(226, 232, 240);
        margin-top: auto;
        fill {
          path: rgb(226, 232, 240);
        }
      }
      a {
        display: flex;
        flex-direction: row;
        text-decoration: none;
        :hover {
          cursor: pointer;
        }
      }
    }
    .title,
    span {
      width: fit-content;
    }
    .title {
      margin: 0;
      width: fit;
    }
    span {
      padding-top: 0px;
    }
    .title {
      font-size: 16px;
    }
    .description {
      .point {
        font-size: 15px;
        color: rgb(148, 163, 184);
      }
    }
    .kicker {
      color: rgb(100, 116, 139);
      span {
        padding-top: 0px;
      }
    }
    .skills {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      flex-direction: row;
      padding-top: 24px;
      .item {
        background-color: #152b39;
        padding: 8px 16px;
        width: fit-content;
        border-radius: 20px;
        span {
          color: #5eead4;
          font-size: 14px;
        }
      }
    }
  }

  @media (min-width: 1100px) {
    width: calc(100%+32px);
    margin-left: -16px;
  }

  @media (max-width: 1100px) {
    padding: 0px;
  }

  @media (max-width: 550px) {
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    width: calc(100% + 8px);
    margin-left: -16px;

    .job-description {
      gap: 0px;
    }
    .description.bullet-points {
      padding-top: 8px;
    }
    .description {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
`;
