export const jobsInfo = [
  {
    time: "2022 — Present",
    title: "Software Engineer · Ria Money Transfer",
    jobSubTitle: "Software Engineer",
    bulletPoints: [
      "• Implemented Scrum methodology for continuous enhancement of www.riamoneytransfer.com, achieving a redesign and development of key features of the web app and migrating from Gatsby to NextJS.",
      "• Collaborated with a team of 4 front-end developers, a QA tester, UI/UX designer, Product Owner, and Tech Lead. • Used Jira for task management, translating Figma designs into UI, and integrating with API endpoints and external services.",
      "• Work and meetings done in English",
    ],
    skills: [
      "JavaScript",
      "React",
      "NextJS",
      "Git",
      "Github",
      "Gatsby",
      "Azure",
      "Figma",
      "SCSS",
      "Netlify",
      "Prismic",
      "SliceMachine",
    ],
    url: "https://www.riamoneytransfer.com",
  },
  {
    time: "2023 — Present",
    title: "Lead FrontEnd Engineer and UI Designer · DogIn",
    jobSubTitle: "Software Engineer",
    bulletPoints: [
      "• Built from scratch the front end of a web app that allows dog owners to connect with dog carers.",
      "• Sole front-end developer for web app, designing (screens, typography, colours) and developing for both desktop and mobile.",
      "• Collaborated with the CEO to plan and execute a successful development roadmap with achieved deadlines.",
      "• Coordinated with backend developer, managing endpoints for data integration (CRUD Apis).",
    ],
    skills: [
      "TypeScript",
      "Angular",
      "Git",
      "Github",
      "Azure",
      "Figma",
      "CSS",
      "Netlify",
    ],
  },
  {
    time: "2021",
    title: "Product Owner · Macal",
    jobSubTitle: "Product Owner",
    bulletPoints: [
      "• Creation and implementation of projects: creation and implementation of the option to purchase cars using car finance. This involved working with 2 designers, 3 developers, 2 operations persons and 1 at marketing to deliver the project.",
    ],
    skills: ["Project Management", "Product Ownership"],
  },
  {
    time: "2020",
    title: "Project Engineer · Copec",
    jobSubTitle: "Project Engineer",
    bulletPoints: [
      "• Analysed two projects financially, gaining approval for one. Executed by visiting stores, managing logistics, negotiating with three suppliers, and coordinating marketing for the successful launch of a new mascot washing service.",
      "• Mobile app proposal: analysis of final client (taxi drivers), interviewing 30 taxi drivers. Did the customer journey, analysed pain points, wishes and desires, achieving creating a business proposal for a mobile app to be developed in a year.",
    ],
    skills: ["Project Management", "Product Ownership"],
  },
  {
    time: "2018 — 2019",
    title: "Project Manager · I2BTech",
    jobSubTitle: "Project Engineer",
    bulletPoints: [
      "• Development and sale of Web App: leadership in the development of a Web App oriented towards online training for outplacement, sold in $107.000 USD. Led a team formed by front-end developers, a ui/ux designer, and a software architect.",
      "• Contact with stakeholders: direct involvement in meetings three times a week with General Managers, Clients and Users, creating the customer journey. Created business requirements for a web app, which we developed in a period of 7 months.",
    ],
    skills: [
      "Project Management",
      "Product Ownership",
      "Scrum Master",
      "UI/UX Design",
    ],
  },
];
