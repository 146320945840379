import React from "react";

export default function Timeline({
  aboutVisible = false,
  experienceVisible = false,
}) {
  return (
    <div className="home timeline">
      <ul>
        {["ABOUT", "EXPERIENCE"].map((item, index) => {
          return (
            <div
              className={`item ${
                aboutVisible === true && item === "ABOUT" ? "expand" : ""
              }
            ${
              experienceVisible === true && item === "EXPERIENCE"
                ? "expand"
                : ""
            }
  `}
              key={index}
            >
              <div className="line"></div>
              <li>{item}</li>
            </div>
          );
        })}
      </ul>
    </div>
  );
}
