import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

export function useInViewStates() {
  const [aboutVisible, setAboutVisible] = useState(false);
  const [experienceVisible, setExperienceVisible] = useState(false);

  const { ref: aboutRef, inView: aboutInView } = useInView({
    threshold: 0.5,
  });

  const { ref: experienceRef, inView: experienceInView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (aboutInView && !aboutVisible) {
      setAboutVisible(true);
    }
    if (!aboutInView) {
      setAboutVisible(false);
    }
  }, [aboutInView, aboutVisible]);

  useEffect(() => {
    if (experienceInView && !experienceVisible) {
      setExperienceVisible(true);
    }
    if (!experienceInView) {
      setExperienceVisible(false);
    }
  }, [experienceInView, experienceVisible]);

  return {
    aboutVisible,
    experienceVisible,
    aboutRef,
    experienceRef,
  };
}
