import styled from "styled-components";

export const BackgroundWrapper = styled.div`
  display: flex;
  background-color: #0f172a;
`;

export const Background = styled.div`
  width: 100vw;
  height: 100%;
  margin: 0 128px;
  padding: 128px 0px;
  display: flex;
  justify-content: center;

  @media (max-width: 1300px) {
    margin: 0 64px;
  }

  @media (max-width: 1100px) {
    padding: 64px 32px;
    margin: 0;
  }
`;
