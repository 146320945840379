import React from "react";
import { ExperienceCardWrapper } from "./ExperienceCard.styles";
import LinkArrow from "../../assets/Icons/LinkArrow";

export default function ExperienceCard({
  time,
  jobTitle,
  jobSubTitle,
  bulletPoints,
  skills,
  index,
  customRef,
  url,
}) {
  return (
    <ExperienceCardWrapper key={index} ref={customRef}>
      <div className="column timeline">
        <span className="content">{time}</span>
      </div>
      <div className="column job-description">
        <div className="title-container">
          {url && (
            <a href={url} target="_blank" rel="noopener noreferrer">
              <h1 className="title">{jobTitle}</h1>
              <LinkArrow />
            </a>
          )}
          {!url && <h1 className="title">{jobTitle}</h1>}
        </div>
        <span className="kicker">{jobSubTitle}</span>
        <div className="description bullet-points">
          {bulletPoints.map((bp, i) => {
            return (
              <p className="point" key={i}>
                {bp}
              </p>
            );
          })}
        </div>
        <div className="skills">
          {skills.map((skill, index) => {
            return (
              <div className="item" key={index}>
                <span>{skill}</span>
              </div>
            );
          })}
        </div>
      </div>
    </ExperienceCardWrapper>
  );
}
