import React from "react";
import "./App.css";
import { Background, BackgroundWrapper } from "./App.styles";
import Intro from "./components/Intro/Intro";

function App() {
  return (
    <div className="App">
      <BackgroundWrapper>
        <Background>
          <Intro />
        </Background>
      </BackgroundWrapper>
    </div>
  );
}

export default App;
